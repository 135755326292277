import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserItem from "../../../ui/items/User.item";
import UserDetailsAdm from "./UserDetails.container";
import UserForm from "./UserForm.container";
import UserFraisEdit from "./UserFraisForm.container";
import UserPassEdit from "./UserPassEdit.container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import ConfirmModal from "../../../ui/modals/ConfirmModal";
import noDataIcon from "../../../assets/img/no_data.png";
import { UserRoleBg, UserRoleLabels, UserStatusBg, UserStatusLabels, etat as userEtat, role as userRole } from "../../../utils/constants/User.enum";
import { useDispatch, useSelector } from "react-redux";
import { clear, enableAdmUser, disableAdmUser, deleteAdmUser, listAdmUsers, updateAdmUser } from "../../../slices/User.slice";
import { listHub } from "../../../slices/Hub.slice";
import UserFormEdit from "./UserFormEdit.container";
import UserDettePassEdit from "./UserDettePassEdit.container";

const msg_del_user = `voulez vous supprimer cet utilisateur ( Tous les colis , historique colis , factures lié a ce compte seront supprimé aussi ) ?`;
const msg_enable_user = `Voulez-vous valider ce compte utilisateur ?`;
const msg_disable_user = `Voulez vous désactiver ce compte utilisateur ?`;

const UserListContainer = (props) => {
  const dispatch = useDispatch();
  const details_ref = useRef();
  const { loading, error } = useSelector((state) => state.user);
  const [show_form, set_show_form] = useState(false);
  const [show_details, set_show_details] = useState(false);
  const [show_edit_frais, set_show_edit_frais] = useState(false);
  const [show_edit_pass, set_show_edit_pass] = useState(false);
  const [show_edit_info, set_show_edit_info] = useState(false);
  const [show_edit_dette_pass, set_show_edit_dette_pass] = useState(false);

  const [lst_user, set_lst_user] = useState([]);
  const [sel_item, set_sel_item] = useState(null);
  const [lst_hub, set_lst_hub] = useState([]);

  //Modal Enable confirm
  const [show_enable_modal, set_show_enable_modal] = useState(false);
  //Modal Disable confirm
  const [show_disable_modal, set_show_disable_modal] = useState(false);
  //Modal Delete confirm
  const [show_delete_modal, set_show_delete_modal] = useState(false);

  const showDetails = (user) => {
    if (user) {
      set_sel_item(user);
      set_show_details(true);
      window.scrollTo(0, 0);
    }
  };
  const onCloseDetails = () => {
    set_sel_item(null);
    set_show_details(false);
  };

  const onShowModal = (item, modal) => {
    if (item) {
      set_sel_item(item);

      if (modal == "enable") set_show_enable_modal(true);
      else if (modal == "disable") set_show_disable_modal(true);
      else if (modal == "delete") set_show_delete_modal(true);
    }
  };

  const onCloseModal = (modal) => {
    //set_sel_item(null);

    if (modal == "enable") set_show_enable_modal(false);
    else if (modal == "disable") set_show_disable_modal(false);
    else if (modal == "delete") {
      set_show_delete_modal(false);
      set_show_details(false);
      set_sel_item(null);
    }
  };

  const onShowForm = (item, source) => {
    if (source == "create") set_show_form(true);
    if (item) {
      set_sel_item(item);
      if (source == "edit-frais") set_show_edit_frais(true);
      else if (source == "edit-pass") set_show_edit_pass(true);
      else if (source == "edit-dette-pass") set_show_edit_dette_pass(true);
      else if (source == "edit-info") set_show_edit_info(true);
    }
  };

  const onCloseForm = (source) => {
    //set_sel_item(null);

    if (source == "create") {
      set_sel_item(null);
      set_show_form(false);
    } else if (source == "edit-frais") set_show_edit_frais(false);
    else if (source == "edit-pass") set_show_edit_pass(false);
    else if (source == "edit-dette-pass") set_show_edit_dette_pass(false);
    else if (source == "edit-info") set_show_edit_info(false);
  };

  const onAfterSubmitForm = (source) => {
    load_data();
    if (source == "create") set_show_form(false);
    else if (source == "edit-frais") set_show_edit_frais(false);
    else if (source == "edit-pass") set_show_edit_pass(false);
  };

  const onEnableUser = () => {
    if (sel_item?.id)
      dispatch(enableAdmUser({ id: sel_item?.id }))
        .unwrap()
        .then((res) => {
          if (res?.done) {
            onCloseModal("enable");
            load_data();
          }
        })
        .catch((err) => console.log(err));
  };

  const onDisableUser = () => {
    if (sel_item?.id)
      dispatch(disableAdmUser({ id: sel_item?.id }))
        .unwrap()
        .then((res) => {
          if (res?.done) {
            onCloseModal("disable");
            load_data();
          }
        })
        .catch((err) => console.log(err));
  };

  const onDeleteUser = () => {
    if (sel_item?.id)
      dispatch(deleteAdmUser({ id: sel_item?.id }))
        .unwrap()
        .then((res) => {
          if (res?.done) {
            onCloseModal("delete");
            load_data();
          }
        })
        .catch((err) => console.log(err));
  };

  const onUpdateUser = (data) => {
    if (data)
      dispatch(updateAdmUser(data))
        .unwrap()
        .then((res) => {
          if (res?.done) {
            load_data();
            details_ref.current.closeHubForm();
            set_show_edit_frais(false)
            set_show_edit_pass(false)
            set_show_edit_info(false)
          }
        })
        .catch((err) => console.log(err));
  };

  const load_data = (filter) => {
    dispatch(listAdmUsers({ filter: filter }))
      .unwrap()
      .then((res) => {
        set_lst_user(Array.isArray(res) ? res : []);
      })
      .catch((err) => console.log(err));
  };

  const load_hub = () => {
    dispatch(listHub())
      .unwrap()
      .then((res) => {
        set_lst_hub(Array.isArray(res) ? res : []);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    load_data();
    load_hub();
  }, []);

  useEffect(() => {
    if (sel_item && Array.isArray(lst_user)) set_sel_item(lst_user.find((el) => el?.id == sel_item?.id));
  }, [lst_user]);

  return (
    <>
      <UserListHeader load_data={load_data} onAddUser={(e) => onShowForm(null, "create")} />

      <div className="col-md-12">
        {error && (
          <div className="alert alert-danger" role="alert">
            <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error}</span>
          </div>
        )}
      </div>

      <div className="col-md-12 mt-2">
        <div className="row no-marg">
          <div className={"col-md-12 bg-wt"}>
            {loading && (
              <div class="col-md-12 p-4 txt-ctr">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {/* {lst_user.map((user) => (
              <UserItem key={user.id} data={user} onDetails={showDetails} />
            ))} */}
            <UserTable lst_user={lst_user} onShowDetails={showDetails} />
          </div>
        </div>
      </div>

      <UserDetailsAdm
        show={show_details}
        onClose={onCloseDetails}
        user={sel_item}
        onShowForm={onShowForm}
        onShowModal={onShowModal}
        load_data={load_data}
        onUpdateUser={onUpdateUser}
        lst_hub={lst_hub}
        loading={loading}
        ref={details_ref}
      />
      <UserForm show={show_form} onClose={e => onCloseForm('create')} onAfterSubmit={onAfterSubmitForm} lst_hub={lst_hub} />
      <UserFraisEdit show={show_edit_frais} onClose={e => onCloseForm('edit-frais')}   onUpdateUser={onUpdateUser} user={sel_item} loading={loading} error={error} />
      <UserPassEdit show={show_edit_pass} onClose={e => onCloseForm('edit-pass')} user={sel_item} onUpdateUser={onUpdateUser} loading={loading} error={error}  />
      <UserDettePassEdit show={show_edit_dette_pass} onClose={e => onCloseForm('edit-dette-pass')} user={sel_item} onUpdateUser={onUpdateUser} loading={loading} error={error}  />
      <UserFormEdit show={show_edit_info} onClose={e => onCloseForm('edit-info')} user={sel_item} onUpdateUser={onUpdateUser} loading={loading} error={error}  />

      <ConfirmModal title={"Activation compte utilisateur"} msg={msg_enable_user} show={show_enable_modal} onHide={(e) => onCloseModal("enable")} onOk={onEnableUser} loading={loading} error={error} />
      <ConfirmModal
        title={"Désactivation compte utilisateur"}
        msg={msg_disable_user}
        show={show_disable_modal}
        onHide={(e) => onCloseModal("disable")}
        onOk={onDisableUser}
        loading={loading}
        error={error}
      />
      <ConfirmModal title={"Suppression compte utilisateur"} msg={msg_del_user} show={show_delete_modal} onHide={(e) => onCloseModal("delete")} onOk={onDeleteUser} loading={loading} error={error} />

      {!lst_user.length && (
        <div className="col-md-12 txt-ctr">
          <br />
          <br />
          <br />
          <img src={noDataIcon} alt="" width="10%" />
          <br />
          <b className="txt-gray fnt-w5">NO DATA FOUND</b>
        </div>
      )}
    </>
  );
};

const UserListHeader = ({ onAddUser, load_data, loading }) => {
  const [filter, set_filter] = useState({ search: "" });

  const onClearFilter = () => {
    set_filter({ search: "", etat: "", role: "" });
    load_data();
  };

  const onFilter = () => {
    let filter_data = {};

    if (filter?.etat) filter_data.etat = filter?.etat;

    if (filter?.role) filter_data.role = filter?.role;

    if (filter?.search) filter_data.search = filter?.search;

    load_data(filter_data);
  };

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-6 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "users"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape">Comptes utilisateurs </b>
        </div>

        <div className="col-md-6 no-padd dsp_inl_flex box_resum ">
          <b className="box_resum-b padd-l10 dvdr-l-fx-gray">
            <span></span>
          </b>
        </div>
      </div>
      <div className="row no-marg rw-header">
        <Form.Group className="col-md-3 px-2">
          <Form.Label className="fnt-sm fnt-w5 m-0">Recherche</Form.Label>
          <Form.Control
            type="search"
            className="form-control inpt_search shadow-none"
            style={{ borderRadius: ".2rem" }}
            placeholder="Recherche ..."
            onChange={(e) => set_filter({ ...filter, search: e.target.value })}
            value={filter?.search}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState" className="col-md-3 px-2">
          <Form.Label className="no-marg small-label">Etat</Form.Label>
          <Form.Control as="select" defaultValue="Choose..." size="sm" className="force-apparence" value={filter?.etat} onChange={(e) => set_filter({ ...filter, etat: e.target.value })}>
            <option value="">Tous</option>
            <option value={userEtat.active}>Active</option>
            <option value={userEtat.disabled}>Inactive</option>
            <option value={userEtat.invalid}>Non validé</option>
          </Form.Control>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState" className="col-md-3 px-2">
          <Form.Label className="no-marg small-label">Role</Form.Label>
          <Form.Control as="select" defaultValue="Choose..." size="sm" className="force-apparence" value={filter?.role} onChange={(e) => set_filter({ ...filter, role: e.target.value })}>
            <option value="">Tous</option>
            <option value={userRole.ADM}>Admin</option>
            <option value={userRole.EXPD}>Expediteur</option>
            <option value={userRole.LIV}>Livreur</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="col-md-3 px-2 pt-4">
          <button className="btn btn_search m-0" onClick={onFilter} disabled={loading}>
            <FontAwesomeIcon icon={["fas", "search"]} />
          </button>
          <button className="btn btn_search bg-success m-0 ms-1" onClick={onFilter} disabled={loading}>
            <FontAwesomeIcon icon={["fas", "sync-alt"]} />
          </button>
          <button className="btn btn_search bg-secondary m-0 ms-1" onClick={onClearFilter} disabled={loading}>
            <FontAwesomeIcon icon={["fas", "times"]} />
          </button>
        </Form.Group>

        <div className="col-md-12 text-end py-1">
          <button className="btn btn-outline-success" type="button" onClick={onAddUser} disabled={loading}>
            <FontAwesomeIcon icon={["fas", "plus"]} /> Nouveau Utilisateur
          </button>
        </div>
      </div>
    </>
  );
};

const UserTable = ({ lst_user, onShowDetails }) => {
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Role</th>
          <th>Status</th>
          <th>Hub Affecté</th>
          <th>...</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(lst_user) &&
          lst_user.map((el) => (
            <tr>
              <td className="fnt-w5">{el?.role == userRole.EXPD ? `${el?.nom_com}(${el?.nom} ${el?.prenom})` : `${el?.nom} ${el?.prenom}`}</td>
              <td>
                <span className={`badge fnt-w5 ${UserRoleBg[el?.role]}`}>{UserRoleLabels[el?.role]}</span>
              </td>
              <td>
                <span className={`badge fnt-w5 ${UserStatusBg[el?.etat]}`}>{UserStatusLabels[el?.etat]}</span>
              </td>
              <td className="fnt-w5">{el?.hub?.nom || "Aucune"}</td>

              <td>
                <button className="btn btn-sm btn-secondary px-3" onClick={() => onShowDetails(el)}>
                  <FontAwesomeIcon icon={["fas", "long-arrow-alt-right"]} />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
export default UserListContainer;
