import React, { useState, useEffect } from "react";
import BarcodeReader from "react-barcode-reader";
import barcodeIcon from "../../../assets/img/barcode-scanner.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import ColisTransferHub from "./ColisTransferHub.item";
// import CapslockModal from '../modals/CapslockModal';
import { useHistory } from "react-router-dom";
import axios from "axios";
import AsyncSelect from "react-select/async";
import UserStorage from "../../../services/UserStorage";
import { api_url } from "../../../services/http_constant";
// import PackageDetails from './PackageDetailsAdm';

import { ColisStatus } from "../../../utils/constants/Colis.enum";
import HubBox from "../home/HubBox.container";
import { useDispatch, useSelector } from "react-redux";

import { getOneColisByFilter, listColisAdm, setError, clear as clear_colis } from "../../../slices/ColisAdm";
import { listHub } from "../../../slices/Hub.slice";
import { createMuTransfert } from "../../../slices/MuAdm";
import { routes } from "../../../utils/helpers/routing.helper";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ColisAdmDetailsContainer from "../package/ColisAdmDetails.container";

const MuTransfertFormContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading: loading_colis, error: error_colis } = useSelector((state) => state.colis_adm);
  const loggedUser = UserStorage.getData();

  const [list, setList] = useState([]);
  const [data_s, setData_s] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [modalCapShow, setModalCapShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const handleCloseCap = () => setModalCapShow(false);
  const handleShowCap = () => setModalCapShow(true);

  const [selectedItm, setSelectedItm] = useState(0);

  const [showP, setShowP] = useState(false);
  const [selData, setSelData] = useState(null);
  const [target_hub, set_target_hub] = useState();
  const [err, set_err] = useState("");
  const [succ, set_succ] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [code_scan, set_code_scan] = useState("");
  const [lst_hub, set_lst_hub] = useState([]);

  const [showDetails, setShowDetails] = useState(false);
  const [sel_colis, set_sel_colis] = useState(false);

  const onItemDetailsClick = data => {
    set_sel_colis(data)
    setShowDetails(true)
}

const hideDetails = () => {
    setShowDetails(false)
}

  const load_hub = () => {
    dispatch(listHub())
      .unwrap()
      .then((res) => {
        if (Array.isArray(res)) {
          set_lst_hub(res.filter((el) => el?.id != loggedUser?.hub?.id));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleScan = (data) => {
    console.log(data);
    set_code_scan(data);
    if (isNaN(data)) {
      console.log(data + " is not  number ! ");
      dispatch(setError("code colis erroné , tapez sur MAJ et essayer svp !"));
    } else {
      console.log(data + " is number ");
      if (data) {
        if (verifColisExist(data)) dispatch(setError("Colis:" + data + " existe déja dans la liste !"));
        else
          dispatch(getOneColisByFilter({ code: data, etat: [ColisStatus["ATT-LIV"], ColisStatus["ATT-TRANSF"], ColisStatus.ENDOM, ColisStatus.RECUPERER, ColisStatus.ANL] }))
            .unwrap()
            .then((colis) => {
              console.log(colis);
              if (colis) setList([colis, ...list]);
              else dispatch(setError("Aucun Colis trouvé avec le réf :" + data + " !"));
            });
      }
    }
  };

  const onSearch = (search, callback) => {
    let locals = searchFromSrc(search);
    dispatch(listColisAdm({ filter: { search: search, etat: [ColisStatus["ATT-LIV"], ColisStatus["ATT-TRANSF"], ColisStatus.ENDOM, ColisStatus.RECUPERER, ColisStatus.ANL] } }))
      .unwrap()
      .then((data) => {
        if (Array.isArray(data) && data.length) callback(data.map((i) => ({ label: `${i.code}.(${i.nom_cl} )`, value: i })));
        else if (locals) callback(locals.map((i) => ({ label: `${i.code}.(${i.nom_cl} )`, value: i })));
        else callback([]);
      });
  };

  const searchFromSrc = (s) => {
    console.log("search from src ");
    let rslt = [];
    list.forEach((elem) => {
      if (String(elem.code).includes(s)) {
        rslt = [...rslt, elem];
      }
    });
    return rslt;
  };

  const onSubmit = () => {
    set_succ("");
    dispatch(setError(""));

    if (!Array.isArray(list)) {
      dispatch(setError("Unité de transfert vide !"));
      return;
    }
    if (list.length == 0) {
      dispatch(setError("Unité de transfert vide !"));
      return;
    }
    if (!target_hub) {
      dispatch(setError("Vous devez choisir le hub de destination !"));
      return;
    }
    let data = {
      colis: list.map((el) => el?.ref),
      id_hub_dest: target_hub,
      nbr_colis: list.length,
    };

    dispatch(createMuTransfert(data))
      .unwrap()
      .then((res) => {
        if (res?.done) {
          set_succ("Mu de transfert crée ");
          history.push("/dashb/mu/transfert/list");
        }
      })
      .catch((err) => console.log(err));
  };

  const onChange = (v) => {
    if (!verifColisExist(v.value.code)) {
      setList([v.value, ...list]);
      setData_s(v);
    } else {
      dispatch(setError("Colis:" + v.value.code + " existe déja dans la liste !"));
    }
  };

  const verifColisExist = (code) => {
    if (!Array.isArray(list)) return false;

    return list.find((el) => el?.code == code) ? true : false;
  };

  const handleError = (err) => {
    console.log(err);
  };

  const removeItem = (code) => {
    if (Array.isArray(list)) setList(list.filter((el) => el?.code != code));
  };

  useEffect(() => {
    load_hub();
    return () => {
      //dispatch(clear());
      dispatch(clear_colis());
    };
  }, []);

  const icon_txt = {
    color: "#5a6c75",
    fontWeight: 500,
  };

  return (
    <>
      <div className="row no-marg tit_rw">
        <div className="col-md-5 no-padd dsp_inl_flex">
          <b className="tit_icon_shape">
            <FontAwesomeIcon icon={["fas", "shipping-fast"]} />
          </b>
          <b className="tit_spacer_shape"></b>
          <b className="tit_text_shape ps-4 d-block pt-0" style={{ clipPath: "none" }}>
            <Link to="/dashb/mu/transfert/list" className="d-block">
              <button className="btn btn-sm py-0 text-primary fnt-w5">
                <FontAwesomeIcon icon={["fas", "long-arrow-alt-left"]} /> Liste des transferts
              </button>
            </Link>
            <span className="d-block">
                Nouveau Transfert Hub
            </span>
          </b>
        </div>

        <div className="col-md-3">
          <span class="badge bg-success fnt-larg mt-2 position-fixed">{list.length}</span>
        </div>

        <div className="col-md-4 no-padd  ">
          <HubBox />
        </div>
      </div>

      <div className="row no-marg rw-header">
        <div className="col-md-6">
          <div className="col txt-ctr mt-2 mb-2">
            <b className="d-block fnt-sm text-start">Réf Colis (Manuelle)</b>
            <AsyncSelect loadOptions={onSearch} value={data_s} onChange={onChange} placeholder="cherchez par Ref colis" />
          </div>
        </div>
        <div className="col-md-3 pt-2">
        <b className="d-block fnt-sm text-start">Hub de déstination</b>
          <Form.Control as="select" className="force-apparence py-2" style={{ borderRadius: 0, color: "#000", fontWeight: 500 }} size="sm" value={target_hub} onChange={(e) => set_target_hub(e.target.value)}>
            <option>Choisissez un Hub </option>
            {Array.isArray(lst_hub) && lst_hub.map((el) => <option value={el?.id}>{el?.nom}</option>)}
          </Form.Control>
        </div>
        <div className="col-md-3">
        <b className="d-block fnt-sm text-start" style={{opacity : 0}}>Action</b>
          {isLoading ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
        <>
            <button className="btn btn-outline-success px-4 mt-2" onClick={onSubmit}>
              <FontAwesomeIcon icon={["fas", "check-circle"]} /> Créer
            </button>
            </>
          )}
        </div>

        <div className="col-md-12 txt-ctr">
          <b className="txt-red">{err && err}</b>
          <b className="txt-blue">{succ && succ}</b>
          {error_colis && (
            <div className="alert alert-danger" role="alert">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> <span className="ps-3">{error_colis}</span>
            </div>
          )}
        </div>
      </div>

      <BarcodeReader onError={handleError} onScan={handleScan} />
      {list.length ? (
        list.map((pack, index) => <ColisTransferHub onItemClick={onItemDetailsClick} data={pack} onCancel={removeItem} key={index} showP={showP} setShowP={setShowP} selData={selData} setSelData={setSelData} />)
      ) : (
        <div className="row no-marg login-container">
          <div className="col-md-3"></div>
          <div className="col-md-6 txt-ctr">
            <img className="barcode_icon" src={barcodeIcon} alt="" width="20%" />
            <p style={icon_txt}>Scan or Search for packages </p>
          </div>
          <div className="col-md-3"></div>
        </div>
      )}

      {/* <DriverModal show={modalShow} onHide={handleClose} /> */}
      {/* <CapslockModal show={modalCapShow} onHide={handleCloseCap} /> */}
      {/* <PackageDetails showP={showP} setShowP={setShowP} selData={selData} setSelData={setSelData} loadData={() => { }} /> */}
      <ColisAdmDetailsContainer show={showDetails} onClose={hideDetails}  sel_colis={sel_colis} load_data={()=> {}} />
    </>
  );
};

export default MuTransfertFormContainer;
